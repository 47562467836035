@import "../css/color-theme/theme";

.section-line {
  border: 1px solid $primary-color;
}

.margin-top-small {
  padding-top: 12px;
}

.padding-left {
  margin-left: 65px;
}
