/* Body theme */
$body-color: #202020;

/* Gradient */
$gradient-start-color: #393c43;
$gradient-end-color: #202020;

$gradient-start-color-secondary: #192b5e;
$gradient-start-color-middle: #0b1530;
$gradient-end-color-secondary: #28355f;

// $gradient-start-color-secondary: #a9e8ff;
// $gradient-end-color-secondary: #17c1ff;

/* h 1 2 3 4 5 6 */
$primary-color: #0052cc;

// $primary-color: #01a1eb;

/* a p l */
$secondary-color: #000000;
$success-color: #28a745;
$danger-color: #dc3545;
$warning-color: #ffc107;
$info-color: #17a2b8;
$pointer-color: #222222;
$text-color: #ffffff;

// body {
//   margin: 0;
//   padding: 0;
//   background-color: $primary-color;
//   background-image: linear-gradient(
//     to left,
//     $gradient-start-color,
//     $gradient-end-color
//   );
// }

// background-color: #202020;
// background-image: linear-gradient(to left, #393c43, #222222);
// }
