@import "../css/color-theme/theme.scss";

.button-pointer-color:hover {
  color: $gradient-start-color-secondary;
}

.link-button-color {
  color: $text-color;
}

.link-button-color:hover {
  text-decoration-color: $text-color;
}

.white-image {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7480%)
    hue-rotate(0deg) brightness(104%) contrast(104%);
}

// read more button

.btn-read-more {
  background-color: white;
  border: none;
  text-decoration: underline;
  font-style: italic;
  color: black;
}
