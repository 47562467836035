.container {
  padding-left: 15px;
  padding-right: 15px;
}

.container-max-width {
  max-width: 2100px;
  margin-left: auto;
  margin-right: auto;
}

// .main-container {
//   max-width: 900px;
//   margin: 0px auto;
//   padding: 0px 20px;
// }
