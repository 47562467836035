@import "../css/color-theme/theme";

h1,
h2,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
  font-weight: 700;
}

.header-text {
  font-family: "arvo";
}

.text-footer {
  font-family: "montserrat", sans-serif, Arial, Helvetica, sans-serif;

  font-size: 14px;
}

h3 {
  color: $primary-color;
  font-family: "lora";
  font-size: 20px;
  margin-top: 0px;
}

p {
  color: $secondary-color;
  font-family: "roboto", sans-serif, Arial, Helvetica, sans-serif;
}

li {
  color: $secondary-color;
  font-family: "roboto", sans-serif, Arial, Helvetica, sans-serif;
}

a {
  color: $secondary-color;
  font-family: "montserrat", sans-serif, Arial, Helvetica, sans-serif;

  &:hover {
    text-decoration: underline;
    color: $pointer-color;
    transition: color 0.7s;
  }
}

.tinted-color {
  color: $text-color;
}

.highlight-text {
  color: $primary-color;
  font-style: italic;
}

.highlight-contact {
  text-decoration: underline;
}
