.image-wrapper {
  position: relative;
  padding-top: 100%; /* 1:1 aspect ratio (square) */
  overflow: hidden;
}

.image-item {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  // object-position: top left;
}

.icon-item {
  margin-right: 25px;
  width: 10%;
  height: 10%;
}

.logo {
  width: auto;
  height: 40px;
  filter: brightness(0%) invert(100%);
}

// cards

.item-shadow {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}
.item-shadows {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.small-image {
  width: 16px;
  height: auto;
}

.small-image-logo {
  width: 27px;
  height: auto;
}

@media (min-width: 767px) {
  .icon-navbar-item {
    display: none;
  }
  .item-shadow {
    border-radius: 0;
    box-shadow: none;
  }

  .shadows {
    box-shadow: none;
  }
  .item-shadow-rectangular {
    border-radius: 0px; /* Adjust the value as needed for the level of rounding */
  }
}

@media (max-width: 948px) {
  .icon-navbar-item {
    width: 14px;
    height: auto;
    filter: brightness(0) invert(1);
  }
  .icon-navbar-mobile {
    display: none;
  }
}
