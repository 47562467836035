/* Mobile styles */

@import "../css/color-theme/theme.scss";

ul {
  padding-left: 0rem;
}

.navbar {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.97;
  z-index: 9998;
  background-image: linear-gradient(
    to left,
    $gradient-start-color-secondary,
    $gradient-start-color-middle,
    $gradient-end-color-secondary
  );

  height: 55px; /* Adjust the height to make the navbar slimmer */
}

.navbar-list {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.navbar-list li {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

a {
  text-decoration: none;
}

.smaller-font-size {
  font-size: 9px;
}

.navbar-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.navbar-item img {
  position: absolute;
  top: 16px; /* Adjust this value to position the image lower */
}

.navbar-item span {
  position: relative;
  margin-top: 27px; /* Adjust this value to position the text as desired */
}

/* Desktop styles */
@media screen and (min-width: 948px) {
  .navbar {
    position: fixed;
    top: 0;
    justify-content: flex-start; /* Align items at the top */
    height: 80px; /* Adjust the height as needed */
    background-image: none;
    opacity: 1;
    transition: top 0.3s ease-in-out; /* Add transition for smooth animation */
  }

  .navbar.hidden {
    top: -80px; /* Move the navbar above the viewport to hide it */
  }

  // .navbar-list li {
  //   margin-right: 30px; /* Adjust the margin as needed */
  //   padding-left: 70px; /* Adjust the margin as needed */
  // }

  .navbar-item img {
    top: 21px; /* Adjust the position as needed */
    padding-left: 80px; /* Adjust the margin as needed */
  }

  .navbar-item span {
    margin-top: 21px; /* Adjust the margin as needed */
  }

  .smaller-font-size {
    font-size: 18px;
    color: white;
  }

  .margin-s-6 {
    margin-left: 31rem;
  }

  .margin-s-2 {
    margin-left: 2rem;
  }
}
